import React from 'react';
import { number, shape, string } from 'prop-types';

const DGScaleResidueTick = ({ payload, x, y }) => (
  <g transform={ `translate(${x},${y})` }>
    <text
      dx={ 5 }
      fill={ String(payload?.value)?.endsWith('-A') ? '#FF7B01' : '#666' }
      textAnchor="end"
      x={ 0 }
      y={ 0 }
    >
      { String(payload?.value)?.split('-')[0] }
    </text>
  </g>
);

DGScaleResidueTick.propTypes = {
  payload: shape({
    value: string,
  }),
  x: number,
  y: number,
};

DGScaleResidueTick.defaultProps = {
  payload: null,
  x: 0,
  y: 0,
};

export default DGScaleResidueTick;
