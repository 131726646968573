import React, { useState } from 'react';
import { func, number } from 'prop-types';

import { Button } from '@material-ui/core';

import IconDownload from 'frontend-common/src/Components/Icon/Download';

const ExportListing = ({
  amount,
  onClick,
  ...props
}) => {
  const [ exporting, setExporting ] = useState(false);

  return (
    <Button
      className="download-listing-button"
      disabled={ !amount || exporting }
      onClick={ async () => {
        setExporting(true);
        try {
          await onClick();
        }
        catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
        setExporting(false);
      } }
      variant="outlined"
      { ...props }
    >
      <IconDownload />
      { exporting ? 'Exporting...' : `Export ${amount}`}
    </Button>
  );
};

ExportListing.propTypes = {
  amount: number,
  onClick: func.isRequired,
};

ExportListing.defaultProps = {
  amount: null,
};

export default ExportListing;
