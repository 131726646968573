import React, { useContext, useEffect } from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';

import { Container } from '@material-ui/core';

import metadataContext from '../contexts/metadata';

import AppBar from './AppBar';
import Errors from './Errors';
import Footer from './Footer';
import Loading from './Loading';
import useAuth from '../Hooks/useAuth';

const Viewport = ({ children, className, title, ...props }) => {
  const { siteTitle } = useContext(metadataContext);
  const { AuthSession } = useAuth();

  useEffect(() => {
    document.title =
      title && title !== siteTitle ? `${siteTitle} - ${title}` : siteTitle;
  }, [siteTitle, title]);

  return (
    <div className='Viewport'>
      <Errors />
      <Loading />

      <AppBar
        siteTitle={siteTitle}
        title={title}
        logoutBtn={AuthSession !== undefined}
        {...props}
      />

      <Container className={classnames('Viewport__content', className)}>
        {children}
      </Container>

      <Footer />
    </div>
  );
};

Viewport.propTypes = {
  children: node,
  className: string,
  title: string
};

Viewport.defaultProps = {
  children: null,
  className: '',
  title: ''
};

export default Viewport;
