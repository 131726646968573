import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import itemsjs from 'itemsjs';

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
} from '@material-ui/core';

import Viewport from 'frontend-common/src/Components/Viewport';

import useLoading from 'frontend-common/src/Hooks/useLoading';
import { list, download } from 'frontend-common/src/Hooks/useStructures';
import downloadFileFromUrl from 'frontend-common/src/utils/downloadFileFromUrl';
import formatDate from 'frontend-common/src/utils/formatDate';

import ButtonExportListing from '../Components/Button/ExportListing';
import ButtonSubmit from '../Components/Button/Submit';
import ListHeaders from '../Components/ListHeaders';

const List = () => {
  const { setFetching } = useLoading();

  const [ data, setData ] = useState(null);
  const [ queried, setQueried ] = useState(null);
  const [ query, setQuery ] = useState('');
  const [ sort, setSort ] = useState('Updated_desc');
  const [ exportLookup, setExportLookup ] = useState(new Set());
  const [ proteinFilters, setProteinFilters ] = useState([]);

  useEffect(() => {
    setFetching(true);

    (async () => {
      const { structures } = (await list()) || {};

      const curated = (structures || []).map(structure => ({
        ...structure,
        Fullname: `${structure.Protein}-${structure.Name}`,
      }));

      const items = itemsjs(curated, {
        searchableFields: [ 'Authors', 'Description', 'Method', 'Mutant', 'Name', 'Protein', 'Fullname' ],
        sortings: {
          Authors_asc: { field: 'Authors', order: 'asc' },
          Authors_desc: { field: 'Authors', order: 'desc' },
          DepDate_asc: { field: 'DepDate', order: 'asc' },
          DepDate_desc: { field: 'DepDate', order: 'desc' },
          Disease_asc: { field: 'Disease', order: 'asc' },
          Disease_desc: { field: 'Disease', order: 'desc' },
          Method_asc: { field: 'Method', order: 'asc' },
          Method_desc: { field: 'Method', order: 'desc' },
          Mutant_asc: { field: 'Mutant', order: 'asc' },
          Mutant_desc: { field: 'Mutant', order: 'desc' },
          Name_asc: { field: 'Name', order: 'asc' },
          Name_desc: { field: 'Name', order: 'desc' },
          Patient_asc: { field: 'Patient', order: 'asc' },
          Patient_desc: { field: 'Patient', order: 'desc' },
          Protein_asc: { field: 'Fullname', order: 'asc' },
          Protein_desc: { field: 'Fullname', order: 'desc' },
          Resolution_asc: { field: 'Resolution', order: 'asc' },
          Resolution_desc: { field: 'Resolution', order: 'desc' },
          Tissue_asc: { field: 'Tissue', order: 'asc' },
          Tissue_desc: { field: 'Tissue', order: 'desc' },
          Updated_asc: { field: 'Updated', order: 'asc' },
          Updated_desc: { field: 'Updated', order: 'desc' },
          UniprotId_asc: { field: 'UniprotId', order: 'asc' },
          UniprotId_desc: { field: 'UniprotId', order: 'desc' },
        },
      });

      const proteins = [ ...new Set(curated.map(({ Protein }) => Protein)) ].sort();

      setData(items);
      setFetching(false);
      setProteinFilters(proteins);
    })();
  }, [ setFetching ]);

  useEffect(() => {
    const newQueried = data?.search({
      per_page: 99999999999,
      query: query.length >= 2 ? query.trim() : '',
      sort,
    })?.data?.items;

    setQueried(newQueried);
  }, [ data, query, sort ]);

  const handleExportListing = () => {
    const proteins = {};

    queried?.forEach(structure => {
      if (!exportLookup.has(structure.Name)) {
        return;
      }

      if (!(structure.Protein in proteins)) {
        proteins[structure.Protein] = [];
      }

      proteins[structure.Protein].push(structure.Name);
    });

    return download({ proteins })
      .then(response => {
        downloadFileFromUrl(response.file);
      });
  };

  const handleStructureExportStatus = structure => {
    const newExportLookup = new Set([ ...exportLookup ]);

    if (newExportLookup.has(structure.Name)) {
      newExportLookup.delete(structure.Name);
    }
    else {
      newExportLookup.add(structure.Name);
    }

    setExportLookup(newExportLookup);
  };

  const handleExportAllNone = () => {
    setExportLookup(new Set(((queried && queried.length !== exportLookup.size) ? queried : []).map(({ Name }) => Name)));
  };

  return (
    <Viewport
      className="list__viewport"
      dashboardBtn={ false }
      newJobBtn={ false }
      updateJobsBtn={ false }
      topBarButtons={ [
        <ButtonSubmit key="submit" />,
      ] }
    >
      <Paper className="list__wrapper">
        <Grid
          alignItems="center"
          container
          justify="space-between"
          wrap="nowrap"
        >
          <Grid
            className="list__filter-wrapper"
            item
          >
            <TextField
              className="list__filter"
              multiline
              onChange={ e => setQuery(e.target.value) }
              placeholder="filter..."
              value={ query }
            />

            <div className="list__filter-button-wrapper">
              { proteinFilters.map(filter => (
                <button
                  key={ filter }
                  className={ classnames('list__filter-button', {
                    'list__filter-button--checked': query === filter,
                  }) }
                  onClick={ () => setQuery(query === filter ? '' : filter) }
                  type="button"
                >
                  { filter }
                </button>
              )) }
            </div>
          </Grid>

          <Grid item>
            <ButtonExportListing
              amount={ exportLookup?.size || 0 }
              onClick={ handleExportListing }
            />
          </Grid>
        </Grid>

        <div className="list__container">
          <Table className="list">
            <TableHead>
              <TableRow>
                <ListHeaders
                  allSelected={ queried?.length === exportLookup.size }
                  handleExportAllNone={ handleExportAllNone }
                  sort={ sort }
                  setSort={ setSort }
                />
              </TableRow>
            </TableHead>

            <TableBody>
              { queried?.map((structure, idx) => (
                <Fragment key={ structure.Name }>
                  { !!idx && !(idx % 20) && (
                    <TableRow className="list-headers__middle-row">
                      <ListHeaders
                        allSelected={ queried?.length === exportLookup.size }
                        handleExportAllNone={ handleExportAllNone }
                        sort={ sort }
                        setSort={ setSort }
                      />
                    </TableRow>
                  ) }

                  <TableRow hover>
                    <TableCell>
                      { /* eslint-disable-next-line prefer-template */ }
                      <Link to={ 'database/' + encodeURIComponent(structure.Protein) }>
                        { structure.Protein }
                      </Link>
                    </TableCell>
                    <TableCell className="center">
                      { /* eslint-disable-next-line prefer-template */ }
                      <Link to={ 'database/' + encodeURIComponent(structure.Protein) + '?strct1=' + structure.Name }>
                        { structure.Name }
                      </Link>
                    </TableCell>
                    <TableCell>{ structure.UniprotId }</TableCell>
                    <TableCell>{ formatDate(structure.UpdatedAt) }</TableCell>
                    <TableCell>{ structure.Mutant }</TableCell>
                    <TableCell>{ structure.Description }</TableCell>
                    <TableCell className="center">{ structure.Residues }</TableCell>
                    <TableCell>{ structure.DepDate }</TableCell>
                    <TableCell>{ structure.Method }</TableCell>
                    <TableCell>{ structure.Resolution }</TableCell>
                    <TableCell>{ structure.Authors }</TableCell>
                    <TableCell>{ structure.Patient ? '+' : '' }</TableCell>
                    <TableCell>{ structure.Disease }</TableCell>
                    <TableCell>{ structure.Tissue }</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={ exportLookup.has(structure.Name) }
                        onChange={ () => handleStructureExportStatus(structure) }
                      />
                    </TableCell>
                  </TableRow>
                </Fragment>
              )) }
            </TableBody>
          </Table>
        </div>
      </Paper>
    </Viewport>
  );
};

export default List;
