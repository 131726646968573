const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
const mandatorySignIn = process.env.REACT_APP_MANDATORY_SIGN_IN !== 'false';
const region = process.env.REACT_APP_REGION;
const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const userPoolWebClientId = process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID;
const endpoint = process.env.REACT_APP_API_ENDPOINT_LOCAL;
const stage = process.env.REACT_APP_STAGE;
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const notifyUrl = process.env.REACT_APP_NOTIFY_URL;
const tool = process.env.REACT_APP_TOOL;

export default {
  Auth: {
    identityPoolId,
    mandatorySignIn,
    region,
    userPoolId,
    userPoolWebClientId,
  },
  API: {
    endpoints: [
      {
        name: 'queue-service',
        endpoint: `${endpoint ?? `https://${apiEndpoint}.execute-api.${region}.amazonaws.com`}/${stage}`,
        region,
      },
      {
        name: 'db-service',
        endpoint: `${endpoint ?? `https://${apiEndpoint}.execute-api.${region}.amazonaws.com`}/${stage}`,
        region,
      },
    ],
  },
  Storage: {
    buckets: {
      proteins: `toolhost-fibrils-proteins-${stage}`,
      results: `toolhost-jobs-results-${stage}`,
      uploads: `toolhost-jobs-uploads-${stage}`,
    },
    identityPoolId,
    level: 'private',
    region,
  },
  NotifyUrl: notifyUrl || '',
  Region: region,
  Tool: tool,
};
