import React, { useCallback, useEffect, useState } from 'react';
import {
  arrayOf,
  instanceOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Stage } from 'ngl';
import classnames from 'classnames';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import addPassiveListener from '../utils/add-passive-listener';
import capitalize from '../utils/capitalize';

const colorSchemes = [
  'atomindex', 'chainid',
  'element', 'hydrophobicity',
  'random', 'residueindex', 'resname', 'sstruc', 'uniform',
  // Removed but still possible options
  // 'bfactor', 'chainindex', 'chainname',
  // 'densityfit', 'electrostatic', 'entityindex', 'entitytype', 'geoquality',
  // 'modelindex', 'moleculetype', 'occupancy', 'value', 'volume',
];

const representations = [
  'backbone', 'ball+stick', 'cartoon',
  'hyperball', 'label', 'licorice', 'line', 'point', 'ribbon', 'rope', 'spacefill',
  'surface', 'trace', 'tube',
  // Removed but still possible options
  // 'axes', 'contact', 'distance', 'helixorient',
  // 'unitcell',
];

const NGLModel = ({ data, placeholder }) => {
  const [ colorScheme, setColorScheme ] = useState('element');
  const [ representation, setRepresentation ] = useState('ball+stick');
  const [ optionsOpen, setOptionsOpen ] = useState(false);
  const [ stage, setStage ] = useState(null);

  const stageRef = useCallback(node => {
    if (!node) {
      return;
    }

    if (!stage || stage.viewer.container !== node) {
      if (stage) {
        stage.removeAllComponents();
      }

      setStage(data ? new Stage(node) : null);
    }
  }, [ data, stage ]);

  useEffect(() => {
    if (stage) {
      stage.removeAllComponents();

      const removeResizeHandler = addPassiveListener(window, 'resize', () => stage.handleResize());

      data?.forEach(({ file, config }) => {
        stage.loadFile(file, { ...config }).then(stageObj => {
          stageObj.addRepresentation(representation, { colorScheme });
          stageObj.autoView();
        });
      });

      stage.handleResize();

      return () => {
        removeResizeHandler();
        stage.removeAllComponents();
      };
    }

    return () => {};
  }, [ colorScheme, data, representation, stage ]);

  return (
    <div
      className="ngl__wrapper"
      ref={ stageRef }
    >
      { !data && (
        <div className="ngl__placeholder">{ placeholder }</div>
      ) }

      { data && (
        <div className="ngl__options">
          <Button
            className={ classnames('ngl__options-button', {
              'ngl__options-button--pressed': optionsOpen,
            }) }
            onClick={ () => setOptionsOpen(!optionsOpen) }
            variant="contained"
          >
            Visualization options
          </Button>

          { optionsOpen && (
            <div className="ngl__options-container">
              <FormControl
                className="ngl__options__option"
                variant="outlined"
              >
                <InputLabel id="select-representation">Representation</InputLabel>
                <Select
                  className="ngl__options__select"
                  label="Representation"
                  labelId="select-representation"
                  onChange={ event => setRepresentation(event.target.value) }
                  value={ representation }
                >
                  { representations.map(choice => (
                    <MenuItem key={ choice } value={ choice }>{ capitalize(choice) }</MenuItem>
                  )) }
                </Select>
              </FormControl>

              <FormControl
                className="ngl__options__option"
                variant="outlined"
              >
                <InputLabel id="select-color-scheme">Color Scheme</InputLabel>
                <Select
                  className="ngl__options__select"
                  label="Color Scheme"
                  labelId="select-color-scheme"
                  onChange={ event => setColorScheme(event.target.value) }
                  value={ colorScheme }
                >
                  { colorSchemes.map(choice => (
                    <MenuItem key={ choice } value={ choice }>{ capitalize(choice) }</MenuItem>
                  )) }
                </Select>
              </FormControl>
            </div>
          ) }
        </div>
      ) }
    </div>
  );
};

const dataPropShape = {
  file: oneOfType([
    instanceOf(window.Blob),
    string,
  ]).isRequired,
  config: shape({
    ext: string,
  }),
};

NGLModel.propTypes = {
  data: arrayOf(shape(dataPropShape)),
  placeholder: string,
};

NGLModel.defaultProps = {
  data: null,
  placeholder: '',
};

export default NGLModel;
