import 'blob-polyfill';
import { useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import amplify, { Storage } from '../lib/Amplify';
import promiseState from '../utils/promiseState';
import { auth } from './useAuth';
import { errors } from './useErrors';
import { JOBS_KEY } from '../lib/constants';

const initialState = {
  jobs: {
    all: null,
    details: {},
    fetching: false,
    files: {},
    newJob: null,
    submitting: false,
  },
};

const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

const setFetching = fetching => setGlobalState('jobs', {
  ...getGlobalState('jobs'),
  fetching,
});

const updateFetching = async () => {
  const jobs = getGlobalState('jobs');

  const promises = [
    jobs.all,
    ...Object.values(jobs.details),
    ...Object.values(jobs.files),
  ];

  if ((await promiseState(promises)) === 'pending') {
    setFetching(true);

    // Don't assume that all promises done means it's no longer fetching, another fetch may have
    // begun since the first updateFetching call and now (this is all async, however unlikely).
    await Promise.all(promises);
    updateFetching();

    return;
  }

  setFetching(false);
};

const setSubmitting = submitting => setGlobalState('jobs', {
  ...getGlobalState('jobs'),
  submitting,
});

const updateSubmitting = async () => {
  const jobs = getGlobalState('jobs');

  const promises = [ jobs.newJob ];

  if ((await promiseState(promises)) === 'pending') {
    setSubmitting(true);

    // Don't assume that all promises done means it's no longer submitting, another fetch may have
    // begun since the first updateSubmitting call and now (this is all async, however unlikely).
    await Promise.all(promises);
    updateSubmitting();

    return;
  }

  setSubmitting(false);
};

const setFiles = files => {
  const jobs = getGlobalState('jobs');

  setGlobalState('jobs', {
    ...jobs,
    files,
  });

  updateFetching();
};

const setNewFile = (Key, contents) => {
  const jobs = getGlobalState('jobs');

  setFiles({
    ...jobs.files,
    [Key]: contents,
  });
};

const setJobs = details => {
  const jobs = getGlobalState('jobs');

  setGlobalState('jobs', {
    ...jobs,
    details,
  });

  updateFetching();
};

const addJob = (JobId, Job) => {
  const jobs = getGlobalState('jobs');

  setJobs({
    ...jobs.details,
    [JobId]: Job,
  });
};

const setList = all => {
  setGlobalState('jobs', {
    ...getGlobalState('jobs'),
    all,
  });

  updateFetching();
};

const setNewJob = newJob => {
  setGlobalState('jobs', {
    ...getGlobalState('jobs'),
    newJob,
  });

  updateSubmitting();
};

const preparePrivateLinks = async (UserId, bucket, list) => list.reduce(async (accF, file) => {
  amplify.withBucket(bucket);

  const files = await accF;
  const key = file.split('/').pop().replace(/\+/g, ' ');
  const filename = file.replace(`private/${UserId}/`, '').replace(/\+/g, ' ');

  files.push({
    key,
    url: await Storage.get(filename, {
      expires: 86400,
      level: 'private',
      identityId: UserId,
    }),
  });

  return files;
}, []);

const getJobsIds = () => {
  /**
   * @type {string[] | null}
   */
  const ids = JSON.parse(window.localStorage.getItem(JOBS_KEY));
  return ids || [];
};

/**
 *
 * @param {string[]} jobsIds
 * @returns
 */
// const setJobsIds = (jobsIds) => window.localStorage.setItem(JOBS_KEY, JSON.stringify(jobsIds));

export const jobs = {
  clear: () => setGlobalState('jobs', { ...initialState.jobs }),

  create: async options => {
    const { isBlocked } = auth;
    if (isBlocked) {
      errors.add(
        'Our system flagged your activity as suspicious. Please try again later.',
      );
      return null;
    }
    const UserId = await auth.getCurrentIdentityId();

    const promise = (async () => {
      const { JobId } = (await amplify.post(
        'job/new',
        Object.entries(options).reduce(
          (acc, [ key, value ]) => {
            if (!(value instanceof File)) {
              acc[key] = value;
            }

            return acc;
          },
          {},
        ),
      )) || {};

      console.log('Submitted JOB ID: ', JobId);

      if (JobId) {
        await Promise.all(Object.values(options).map(file => {
          if (file instanceof File) {
            return jobs.putFile({
              key: `${JobId}/${file.name}`,
              file,
              bucket: 'uploads',
            });
          }

          return null;
        }));

        await amplify.post('job/queue', {
          JobId,
          UserId,
          // NotifyUrl: getNotifyUrl(JobId),
        });
      }

      return JobId;
    })();

    setNewJob(promise);

    return promise;
  },

  get: JobId => {
    const existingJob = getGlobalState('jobs').details[JobId];
    if (existingJob) return existingJob;

    const promise = (async () => {
      try {
        const job = await amplify.post('job/fetch', { JobId });
        const { UserId } = job;
        job.Files = await preparePrivateLinks(UserId, 'uploads', job.Files);
        job.Results = await preparePrivateLinks(UserId, 'results', job.Results);
        return job;
      }
      catch (ex) {
        // TODO: handle errors
        console.error(ex);
        errors.add(
          'Error fetching jobs, please reach out to us if the issue persists.',
        );
        return null;
      }
    })();

    addJob(JobId, promise);

    return promise;
  },

  list: () => {
    const { isBlocked } = auth;
    if (isBlocked) return [];

    const existingList = getGlobalState('jobs').all;
    if (existingList) return existingList;

    const promise = (async () => {
      try {
        const UserId = await auth.getCurrentIdentityId();
        const JobsIds = getJobsIds();
        const List = await amplify.post('job/list', {
          UserId,
          JobsIds,
        });

        // Loop through List and remove duplicates based on JobId
        // This is a temporary fix, we need to fix the backend endpoint listing jobs too
        const uniqueList = List.reduce((acc, job) => {
          const existingJob = acc.find(j => j.JobId === job.JobId);
          if (!existingJob) {
            acc.push(job);
          }
          return acc;
        }, []);

        return await uniqueList.reduce(async (accJ, job) => {
          const list = await accJ;

          const Files = await preparePrivateLinks(job.UserId, 'uploads', job.Files);
          const Results = await preparePrivateLinks(job.UserId, 'results', job.Results);

          return [
            ...list,
            {
              ...job,
              Files,
              Results,
            },
          ];
        }, Promise.resolve([]));
      }
      catch (ex) {
        // TODO: handle errors
        console.error(ex);
        errors.add(
          'Error fetching jobs, please reach out to us if the issue persists.',
        );
        return null;
      }
    })();

    setList(promise);

    return promise;
  },

  /**
   *
   * @param {Parameters<typeof Storage.get>['1'] & {
   * Key: string;
   * }} params
   */
  readFile: ({ Key, bucket, ...options }) => {
    const existingContents = getGlobalState('jobs').files[Key];
    if (existingContents) {
      return existingContents;
    }

    const promise = (async () => {
      try {
        amplify.withBucket(bucket);
        const {
          Body,
          $metadata: { httpStatusCode: status },
        } = await Storage.get(Key, {
          ...options,
          download: true,
          validateObjectExistence: true,
        });
        if (!Body || status === 404) return null;
        return Body.text();
      }
      catch (e) {
        const {
          $metadata: { httpStatusCode: status },
        } = e;
        if (status === 404) return null;

        errors.add(
          'Error fetching job file, please reach out to us if the issue persists.',
        );
        return undefined;
      }
    })();

    setNewFile(Key, promise);

    return promise;
  },

  putFile: async ({ key, file, bucket }) => {
    try {
      amplify.withBucket(bucket);
      return Storage.put(key, file);
    }
    catch (ex) {
      errors.add(ex);
      return null;
    }
  },

  update: () => {
    setJobs({});
    setList(null);
  },
};

// Clear the Jobs state when the user logs in or out
auth.listen(jobs.clear, jobs.clear);

const useJobs = () => {
  const [ state ] = useGlobalState('jobs');

  Object.assign(jobs, state);

  return useMemo(() => ({
    ...state,
    ...jobs,
  }), [ state ]);
};

export default useJobs;
