/* eslint-disable react/no-array-index-key */

import React, { useMemo, useState } from 'react';
import { arrayOf, string } from 'prop-types';
import classnames from 'classnames';

import useQueryParams from 'frontend-common/src/Hooks/useQueryParams';

const roundValue = (value, radix) => Math.round(value * (10 ** radix)) / (10 ** radix);

const ComparisonTable = ({ data }) => {
  const curated = useMemo(() => {
    const cloned = [ ...(data || []).map(line => [ ...line ]) ];

    // Ignore the first line, assume it's the same keys as for each line in the same order
    cloned.shift();

    return cloned.map(line => ({
      structure: line[0],
      values: line.splice(1).map(value => Number(value)),
    }));
  }, [ data ]);

  const { queryParams: { strct1, strct2 }, set } = useQueryParams();

  const [ hover, setHover ] = useState();

  if (!curated.length) {
    return null;
  }

  return (
    <table className="comparison-table">
      <tbody>
        { curated.map((line, idxL) => (
          <tr
            key={ `line-${idxL}` }
            className={ classnames({
              'comparison-row--hover': idxL === hover?.row || idxL === hover?.column,
            }) }
          >
            { line.values.map((value, idxV) => (
              <td
                key={ `value-${idxL}-${idxV}` }
                className={ classnames({
                  'comparison-header': idxL === idxV,
                  'comparison-header--strct1': idxL === idxV && line.structure === strct1,
                  'comparison-header--strct2': idxL === idxV && line.structure === strct2,
                  'comparison-column--hover': idxV === hover?.column || idxV === hover?.row,
                }) }
                onMouseEnter={ () => setHover(idxL !== idxV && { column: idxV, row: idxL }) }
                onMouseLeave={ () => setHover() }
              >
                { idxL === idxV && line.structure }

                { idxL > idxV && (
                  <button
                    className="comparison-label"
                    onClick={ () => set({
                      strct1: line.structure,
                      strct2: curated[idxV].structure,
                    }) }
                    type="button"
                  >
                    { roundValue(value, 2) }
                  </button>
                ) }

                { idxL < idxV && (
                  <button
                    className="comparison-label"
                    onClick={ () => set({
                      strct1: line.structure,
                      strct2: curated[idxV].structure,
                    }) }
                    type="button"
                  >
                    <div
                      className="comparison-marker"
                      style={ { opacity: roundValue(value, 2) } }
                    />
                  </button>
                ) }
              </td>
            )) }
          </tr>
        )) }
      </tbody>
    </table>
  );
};

ComparisonTable.propTypes = {
  data: arrayOf(arrayOf(string)),
};

ComparisonTable.defaultProps = {
  data: null,
};

export default ComparisonTable;
