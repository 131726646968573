import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from '../utils/queryString';

export const getQueryParams = ({ search }) => {
  const params = {};

  const searchParams = new URLSearchParams(search);
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

const useQueryParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const queryParams = useMemo(() => getQueryParams({ search }), [ search ]);

  const set = useCallback(values => {
    const newParams = { ...queryParams };
    Object.entries(values).forEach(([ key, value ]) => {
      newParams[key] = value;
    });

    const paramsStr = queryString(newParams);
    if (paramsStr !== search) {
      if (paramsStr) {
        history.push(`${pathname}?${paramsStr}`);
      }
      else {
        history.push(pathname);
      }
    }
  }, [ history, pathname, queryParams, search ]);

  return { queryParams, set };
};

export default useQueryParams;
