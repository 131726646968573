import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { bool, node, arrayOf } from 'prop-types';
import classnames from 'classnames';

import {
  Link,
  AppBar as MUIAppBar,
  Toolbar,
  Typography
} from '@material-ui/core';

import metadataContext from '../contexts/metadata';

import GoTo from './GoTo';
import GoToDashboard from './GoToDashboard';
import Logout from './Logout';
import UpdateJobs from './UpdateJobs';

const AppBar = ({
  dashboardBtn,
  logoutBtn,
  newJobBtn,
  updateJobsBtn,
  title,
  topBarButtons
}) => {
  const { logo, siteTitle } = useContext(metadataContext);

  const history = useHistory();
  const location = useLocation();

  return (
    <MUIAppBar position='fixed'>
      <Toolbar
        className={classnames('AppBar__content', {
          'AppBar__content--center':
            !dashboardBtn && !newJobBtn && !logoutBtn && !updateJobsBtn
        })}>
        <Typography variant='h6' className='AppBar__title'>
          {location.pathname !== '/' && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              className='AppBar__title-logo AppBar__title-link'
              color='inherit'
              onClick={(e) => {
                history.push('/');
                e.preventDefault();
              }}
              underline='none'>
              <img src={logo} alt={siteTitle} />
            </Link>
          )}
          {title}
        </Typography>

        <div className='AppBar__actions'>
          {topBarButtons}

          {updateJobsBtn && <UpdateJobs />}

          {newJobBtn && (
            <GoTo
              route='/newjob'
              title='New Job'
              variant='contained'
              color='primary'
            />
          )}

          {dashboardBtn && <GoToDashboard />}

          {logoutBtn && <Logout />}
        </div>
      </Toolbar>
    </MUIAppBar>
  );
};

AppBar.propTypes = {
  dashboardBtn: bool,
  logoutBtn: bool,
  newJobBtn: bool,
  updateJobsBtn: bool,
  topBarButtons: arrayOf(node),
  title: node
};

AppBar.defaultProps = {
  dashboardBtn: true,
  logoutBtn: false,
  newJobBtn: true,
  updateJobsBtn: true,
  title: '',
  topBarButtons: []
};

export default AppBar;
