import { useEffect, useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { Auth, Hub } from '../lib/Amplify';
import useLocalSession, { getLocalSession } from "./useLocalSession";
import awsConfig from '../lib/aws-config';

const initialState = {
  auth: {
    AuthIdentity: undefined,
    AuthSession: undefined,
    IsAdmin: undefined
  },
};

const { setGlobalState, useGlobalState } = createGlobalState(initialState);

const setAuth = (AuthSession) =>
  setGlobalState('auth', {
    AuthSession,

    // The Cognito Identity Id is buried in the AuthSession, we'll need it as
    // it's the id used to reference this user's jobs.
    AuthIdentity:
      AuthSession &&
      Object.keys(AuthSession.storage)
        .filter((key) => key.startsWith('aws.cognito.'))
        .reduce(
          (acc, key) => ({
            ...acc,
            [key.split('.')[2]]: AuthSession.storage[key]
          }),
          {}
        ),

    IsAdmin:
      AuthSession?.signInUserSession?.accessToken?.payload?.[
        'cognito:groups'
      ]?.includes('administrator')
  });

// Set the state on page open.
Auth.currentAuthenticatedUser()
  .then(setAuth)
  .catch((err) => {
    if (['not authenticated', 'The user is not authenticated'].includes(err)) {
      setAuth(null);
    } else {
      console.error(err);
    }
  });

export const auth = {
  ...initialState.auth,

  getCurrentIdentityId: async () => {
    const user = await Auth.currentUserInfo();
    return user?.id || getLocalSession();
  },

  listen: (onLogin, onLogout) => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          if (onLogin) {
            onLogin(data.payload.data);
          }
          break;

        case 'signOut':
          if (onLogout) {
            onLogout(null);
          }
          break;

        default:
          break;
      }
    });
  },

  logout: () =>
    Auth.signOut().catch((err) => {
      console.error(err);
    })
};

// Listen for login and logout events.

auth.listen(setAuth, setAuth);

export default () => {
  const [authAWS] = useGlobalState('auth');
  const localSession = useLocalSession();

  useEffect(() => {
    if (authAWS.AuthSession) {
      Object.assign(auth, authAWS);
    }
  }, [authAWS]);

  return useMemo(() => ({
    ...auth,
    ...authAWS,
    authenticated: !!authAWS.AuthSession || (!awsConfig.Auth.mandatorySignIn && !!localSession),
    localSession,
  }), [ authAWS, localSession ]);
};
