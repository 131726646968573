import React from 'react';
import { any, shape, string } from 'prop-types';

import { download } from 'frontend-common/src/Hooks/useStructures';

import NGL from 'frontend-common/src/Components/NGL';
import downloadFileFromUrl from 'frontend-common/src/utils/downloadFileFromUrl';

import ButtonExportIndividual from './Button/ExportIndividual';

const StructurePreview = ({ modelData, strct }) => {
  const handleExportIndividual = proteins => download({ proteins })
    .then(response => {
      downloadFileFromUrl(response.file);
    });

  return (
    <div className="structure-model">
      { strct && (
        <>
          <div className="structure-info">
            <h3>{ strct.Name }</h3>

            { !!strct.Description && (
              <p>{ strct.Description }</p>
            ) }

            { (!!strct.Disease || !!strct.Tissue) && (
              <p>
                { strct.Disease }
                { !!strct.Disease && !!strct.Tissue && ', ' }
                { strct.Tissue }
              </p>
            ) }

            { !!strct.Mutant && (
              <p>{ `Mutation: ${strct.Mutant}` }</p>
            ) }

            { !!strct.Method && (
              <p>{ `Experimental Method: ${strct.Method}` }</p>
            ) }

            { !!strct.Resolution && (
              <p>{ `Resolution or Conformers curated: ${strct.Resolution}` }</p>
            ) }

            { !!strct.Authors && (
              <p>{ strct.Authors }</p>
            ) }
          </div>

          <NGL
            data={ modelData !== 'failed' ? modelData : null }
            placeholder={ modelData === 'failed' ? 'Failed to load model' : 'Loading...' }
          />

          <ButtonExportIndividual
            onClick={ () => handleExportIndividual({ [strct.Protein]: [ strct.Name ] }) }
          />
        </>
      ) }
    </div>
  );
};

StructurePreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modelData: any,
  strct: shape({
    Authors: string,
    Description: string,
    Disease: string,
    Method: string,
    Mutant: string,
    Resolution: string,
    Tissue: string,
  }),
};

StructurePreview.defaultProps = {
  modelData: null,
  strct: null,
};

export default StructurePreview;
