import React, { useMemo } from 'react';

import { CircularProgress } from '@material-ui/core';

import useAuth from '../Hooks/useAuth';
import useJobs from '../Hooks/useJobs';
import useLoading from '../Hooks/useLoading';

const Loading = () => {
  const { AuthSession } = useAuth();
  const jobs = useJobs();
  const loading = useLoading();

  const label = useMemo(() => {
    if (AuthSession === undefined) {
      return 'initializing...';
    }

    if (jobs?.submitting || loading?.submitting) {
      return "working...";
    }

    if (jobs?.fetching || loading?.fetching) {
      return "loading...";
    }

    return '';
  }, [ AuthSession, jobs, loading ]);

  if (!label) {
    return null;
  }

  return (
    <div className='Loading__outer-wrapper'>
      <div className='Loading__inner-wrapper'>
        <CircularProgress />
        <span>{label}</span>
      </div>
    </div>
  );
};

export default Loading;
