const promiseState = async promise => {
  // If given an array of promises, check the pending state of the equivalent Promise.all(promises)
  if (Array.isArray(promise)) {
    const states = await Promise.all(promise.map(promiseState));

    if (states.some(state => state === 'pending')) {
      return 'pending';
    }

    if (states.some(state => state === 'rejected')) {
      return 'rejected';
    }

    return 'fulfilled';
  }

  // If we're not given a promise, assume it's fulfilled to the given value.
  if (!(promise instanceof Promise)) {
    return 'fulfilled';
  }

  // Symbols and RegExps are never content-equal
  const uniqueValue = typeof window !== 'undefined' && window.Symbol ? Symbol('unique') : /unique/;

  try {
    const value = await Promise.race([ promise, Promise.resolve(uniqueValue) ]);
    return value === uniqueValue ? 'pending' : 'fulfilled';
  }
  catch (ex) {
    return 'rejected';
  }
};

export default promiseState;
