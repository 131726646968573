import React, { useEffect } from 'react';
import { elementType } from 'prop-types';

import { useHistory } from 'react-router-dom';

import useAuth from '../../Hooks/useAuth';

const Authenticator = ({ Comp, ...props }) => {
  const { authenticated } = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    if (!authenticated) {
      push('/login');
    }
  }, [ authenticated, push ]);

  return authenticated && <Comp { ...props } />;
};

Authenticator.propTypes = {
  Comp: elementType.isRequired,
};

export default Authenticator;
