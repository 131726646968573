import { createGlobalState } from 'react-hooks-global-state';

import amplify, { Storage } from '../lib/Amplify';

const upload = (name, file) => {
  amplify.withBucket('proteins');

  return Storage.put(name, file, {
    level: 'public',
  }).then(result => result);
};

const create = async (data, correlation) => {
  const { Name } = data.protein;

  if (correlation) {
    await upload(`${Name}-Correlation.txt`, correlation);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const structure of data.structures) {
    if (structure.pdb) {
      // eslint-disable-next-line no-await-in-loop
      await upload(`${Name}-${structure.Name}.pdb`, structure.pdb);
    }
  }

  return amplify.post('proteins', { data }, 'db-service');
};

const get = async name => {
  const data = await amplify.get(`proteins/${name}`, 'db-service');

  data?.structures?.sort((a, b) => {
    if (a?.Name < b?.Name) {
      return -1;
    }
    if (a?.Name > b?.Name) {
      return 1;
    }
    return 0;
  });

  return data;
};

const { getGlobalState, setGlobalState } = createGlobalState({ pdb: {} });

const setNewFile = (Key, contents) => {
  const pdb = getGlobalState('pdb');

  setGlobalState('pdb', {
    ...pdb,
    [Key]: contents,
  });
};

const listFiles = ({ bucket, ...options }) => {
  try {
    amplify.withBucket(bucket);
    return Storage.list('', options);
  }
  catch (ex) {
    // We don't need to show these errors to the user, we are gracefully hiding any sections that use these files,
    // or showing a smaller in-section message instead.
    console.error(ex);
    return null;
  }
};

const readFile = ({ Key, bucket, ...options }) => {
  const existingContents = getGlobalState('pdb')[Key];
  if (existingContents) {
    return existingContents;
  }

  const promise = (async () => {
    try {
      amplify.withBucket(bucket);
      const { Body } = await Storage.get(Key, {
        ...options,
        download: true,
      });
      return Body.text();
    }
    catch (ex) {
      // We don't need to show these errors to the user, we are gracefully hiding any sections that use these files,
      // or showing a smaller in-section message instead.
      console.error(ex);
      return null;
    }
  })();

  setNewFile(Key, promise);

  return promise;
};

const useProteins = () => ({
  create,
  get,
  listFiles,
  readFile,
});

export default useProteins;
