import React from 'react';
import { func, string } from 'prop-types';
import classnames from 'classnames';

import { TableCell } from '@material-ui/core';

const ListHeader = ({
  allSelected, handleExportAllNone, sort, setSort,
}) => {
  const isSorting = name => sort.startsWith(name) && sort.split('_')[1];

  return [
    { field: 'Protein', label: 'Protein' },
    { className: 'center', field: 'Name', label: 'PDB ID' },
    { className: 'center', field: 'UniprotId', label: 'Uniprot ID' },
    { field: 'Updated', label: 'Updated' },
    { field: 'Mutant', label: 'Mutant' },
    { className: 'x-larger', label: 'Description' },
    { label: 'Residues (Length)' },
    { field: 'DepDate', label: 'Dep. Date' },
    { field: 'Method', label: 'Method' },
    { className: 'larger', field: 'Resolution', label: 'Resolution' },
    { className: 'x-larger', field: 'Authors', label: 'Authors' },
    { field: 'Patient', label: 'From Patient' },
    { className: 'larger', field: 'Disease', label: 'Disease' },
    { field: 'Tissue', label: 'Tissue' },
    { label: 'Export' },
  ].map(({ className, field, label }) => {
    if (field) {
      return (
        <TableCell
          key={ label }
          className={ classnames('list-header__cell', className) }
        >
          <button
            className={ classnames('list-header', {
              [`sorting--${isSorting(field)}`]: isSorting(field),
            }) }
            onClick={ () => setSort(sort === `${field}_asc` ? `${field}_desc` : `${field}_asc`) }
            type="button"
          >
            { label }
            <img
              className="list-header__sort-icon"
              src="../static/media/menu-right.svg"
            />
          </button>
        </TableCell>
      );
    }

    if (label === 'Export') {
      return (
        <TableCell
          key={ label }
          className={ classnames('list-header__cell', className) }
        >
          <button
            className="list-header list__export-all-none"
            onClick={ handleExportAllNone }
            type="button"
          >
            { allSelected ? 'Export None' : 'Export All' }
          </button>
        </TableCell>
      );
    }

    return (
      <TableCell
        key={ label }
        className={ classnames('list-header__cell', 'list-header', className) }
      >
        { label }
      </TableCell>
    );
  });
};

ListHeader.propTypes = {
  handleExportAllNone: func.isRequired,
  sort: string.isRequired,
  setSort: func.isRequired,
};

export default ListHeader;
