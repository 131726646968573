import React, { memo } from 'react';

import useErrors from '../Hooks/useErrors';

const Errors = () => {
  const errors = useErrors();

  if (!errors.messages.length) {
    return null;
  }

  return (
    <div className="Errors__wrapper">
      <button
        type="button"
        className="Errors__dismiss"
        title="Dismiss"
        onClick={ () => errors.clear() }
      >
        X
      </button>

      { errors.messages.map(msg => (
        <div
          key={ `error-message-${msg}` }
          className="Errors__message"
        >
          { msg }
        </div>
      )) }
    </div>
  );
};

export default memo(Errors);
