import React from 'react';

import { Button } from '@material-ui/core';

import useJobs from '../Hooks/useJobs';

export default () => {
  const jobs = useJobs();

  return (
    <Button
      className="ButtonUpdate-jobs"
      type="button"
      variant="contained"
      onClick={ jobs.update }
    >
      Update jobs status
    </Button>
  );
};
