import amplify from '../lib/Amplify';

export const list = () => amplify.get('structures', 'db-service');
export const download = body => amplify.post('structures/export', body, 'db-service');

const useStructures = () => ({
  list,
  download,
});

export default useStructures;
