import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import classnames from 'classnames';

import Collapsible from 'frontend-common/src/Components/Collapsible';
import GoTo from 'frontend-common/src/Components/GoTo';
import Viewport from 'frontend-common/src/Components/Viewport';

import useLoading from 'frontend-common/src/Hooks/useLoading';
import useQueryParams from 'frontend-common/src/Hooks/useQueryParams';
import useProteins from 'frontend-common/src/Hooks/useProteins';

import ComparisonTable from '../Components/ComparisonTable';
import ComparisonTableLegend from '../Components/ComparisonTableLegend';
import DGScale from '../Components/DGScale';
import StructurePreview from '../Components/StructurePreview';

const getMutation = (residue, idx, structure) => {
  const res = structure?.Sequence.find(strct => strct.resNum === idx)?.res;
  return res && res !== residue ? res : null;
};

const View = ({ match: { params: { name } } }) => {
  const { setFetching } = useLoading();
  const { get, readFile } = useProteins();

  const [ data, setData ] = useState(null);

  useEffect(() => {
    setFetching(true);

    (async () => {
      const newData = (await get(name)) || {};

      setData(newData);
      setFetching(false);
    })();
  }, [ get, name, setFetching ]);

  const { queryParams, set } = useQueryParams();
  const strct1 = queryParams.strct1 && data?.structures.find(structure => structure.Name === queryParams.strct1);
  const strct2 = queryParams.strct2 && data?.structures.find(structure => structure.Name === queryParams.strct2);

  const toggleStructure = strct => {
    if (strct1 === strct) {
      set({ strct1: null });
    }
    else if (strct2 === strct) {
      set({ strct2: null });
    }
    else if (!strct1) {
      set({ strct1: strct.Name });
    }
    else if (!strct2) {
      set({ strct2: strct.Name });
    }
  };

  const [ comparison, setComparison ] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    (async () => {
      const textContents = await readFile({
        Key: `${data.protein.Name}-Correlation.txt`,
        bucket: 'proteins',
        contentType: 'text/plain',
        level: 'public',
      });

      if (textContents) {
        setComparison(
          textContents
            .split('\n')
            .filter(line => line)
            .map(line => line.split('\t')),
        );
      }
    })();
  }, [ data, readFile, strct1 ]);

  const [ model1Data, setModel1Data ] = useState();
  const [ model2Data, setModel2Data ] = useState();

  useEffect(() => {
    (async () => {
      if (strct1) {
        const pdbContents = await readFile({
          Key: `${strct1.Protein}-${strct1.Name}.pdb`,
          bucket: 'proteins',
          contentType: 'text/plain',
          level: 'public',
        });

        if (!pdbContents) {
          setModel1Data('failed');
          return;
        }

        setModel1Data([ {
          file: new window.Blob([ pdbContents ], { type: 'text/plain' }),
          config: { ext: 'pdb' },
        } ]);
      }
      else {
        setModel1Data();
      }
    })();
  }, [ readFile, strct1 ]);

  useEffect(() => {
    (async () => {
      if (strct2) {
        const pdbContents = await readFile({
          Key: `${strct2.Protein}-${strct2.Name}.pdb`,
          bucket: 'proteins',
          contentType: 'text/plain',
          level: 'public',
        });

        if (!pdbContents) {
          setModel2Data('failed');
          return;
        }

        setModel2Data([ {
          file: new window.Blob([ pdbContents ], { type: 'text/plain' }),
          config: { ext: 'pdb' },
        } ]);
      }
      else {
        setModel2Data();
      }
    })();
  }, [ readFile, strct2 ]);

  return (
    <Viewport
      className="protein"
      dashboardBtn={ false }
      newJobBtn={ false }
      updateJobsBtn={ false }
      title={ data?.protein.Name }
      topBarButtons={ [
        <GoTo
          key="go-to-proteins-create"
          variant="contained"
          color="secondary"
          route="database"
          title="Database"
        />,
      ] }
    >
      { !!data && (
        <>
          <div className="protein-selectors">
            <div className="protein-structures">
              <div className="protein-structures__group-name">
                Wild Type
              </div>
              <div className="protein-structures__list">
                { data.structures.map(structure => !structure.Mutant && (
                  <button
                    key={ structure.Name }
                    className={ classnames('protein-structure', {
                      'protein-structure--strct1': strct1 === structure,
                      'protein-structure--strct2': strct2 === structure,
                    }) }
                    onClick={ () => toggleStructure(structure) }
                    type="button"
                  >
                    { structure.Name }
                  </button>
                )) }
              </div>
            </div>

            <div className="protein-structures">
              <div className="protein-structures__group-name">
                Mutant
              </div>
              <div className="protein-structures__list">
                { data.structures.map(structure => !!structure.Mutant && (
                  <button
                    key={ structure.Name }
                    className={ classnames('protein-structure', {
                      'protein-structure--strct1': strct1 === structure,
                      'protein-structure--strct2': strct2 === structure,
                    }) }
                    onClick={ () => toggleStructure(structure) }
                    type="button"
                  >
                    { structure.Name }
                  </button>
                )) }
              </div>
            </div>
          </div>

          <Collapsible
            label="Main Sequence"
            show
          >
            <div className="main-sequence">
              { data.protein.Sequence
                .map((entry, idx) => {
                  if (!entry?.residue) {
                    return null;
                  }

                  const mut1 = getMutation(entry.residue, idx, strct1);
                  const mut2 = getMutation(entry.residue, idx, strct2);

                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={ idx }
                      className={ classnames('main-sequence__residue', {
                        'protein-structure--strct1': strct1?.Sequence.find(({ resNum }) => resNum === idx),
                        'protein-structure--strct2': strct2?.Sequence.find(({ resNum }) => resNum === idx),
                        'protein-structure--mutation': mut1 || mut2,
                        'protein-structure--apr': entry.APR,
                      }) }
                    >
                      { entry.residue }

                      { !!mut1 && (
                        <div
                          className={ classnames('main-sequence__strct1-mutation', {
                            'main-sequence__mutation--smaller': mut1.length > 1,
                          }) }
                        >
                          { mut1 }
                        </div>
                      ) }

                      { !!mut2 && (
                        <div
                          className={ classnames('main-sequence__strct2-mutation', {
                            'main-sequence__mutation--smaller': mut2.length > 1,
                          }) }
                        >
                          { mut2 }
                        </div>
                      ) }
                    </div>
                  );
                }) }
            </div>

            <div className="main-sequence__legend">
              <div className="main-sequence__legend-item">
                <div className="main-sequence__residue protein-structure--apr" />
                Experimental APR
              </div>
            </div>
          </Collapsible>

          { !!comparison && (
            <Collapsible
              className="protein-comparison"
              label="Correlation Matrix"
              show
            >
              <ComparisonTableLegend />
              <ComparisonTable data={ comparison } />
            </Collapsible>
          ) }

          { (strct1 || strct2) && (
            <Collapsible
              className="structure-models"
              label="Information and 3D Models"
              show
            >
              <StructurePreview
                modelData={ model1Data }
                strct={ strct1 }
              />
              <StructurePreview
                modelData={ model2Data }
                strct={ strct2 }
              />
            </Collapsible>
          ) }

          { (strct1 || strct2) && (
            <Collapsible
              label="Thermodynamic Profile"
              show
            >
              <DGScale data={ data } />
            </Collapsible>
          ) }

          { !!data?.structures?.[0]?.UniprotId && (
            <div className="protein__external-links">
              <div className="protein__external-links-container">
                <a
                  href={ `https://www.uniprot.org/uniprot/${data.structures[0].UniprotId}` }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  { `UniProtKB - ${data.structures[0].UniprotId}`}
                </a>
              </div>
            </div>
          ) }

          <div className="protein__external-links">
            Databases with related content on experimentally determined aggregation prone regions

            <div className="protein__external-links-container">
              <a
                href="http://waltzdb.switchlab.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                WALTZ-DB 2.0
              </a>
              <a
                href="https://amypro.net/#/"
                rel="noopener noreferrer"
                target="_blank"
              >
                AmyPro
              </a>
              <a
                href="https://web.iitm.ac.in/bioinfo2/cpad2/"
                rel="noopener noreferrer"
                target="_blank"
              >
                CPAD
              </a>
            </div>
          </div>
        </>
      ) }
    </Viewport>
  );
};

View.propTypes = {
  match: shape({
    params: shape({
      name: string,
    }),
  }).isRequired,
};

export default View;
