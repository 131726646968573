import React from 'react';
import { useHistory } from 'react-router-dom';
import { node, string } from 'prop-types';

import { Button } from '@material-ui/core';

const GoTo = ({ route, title, ...props }) => {
  const history = useHistory();

  return (
    <Button
      className='GoTo'
      onClick={() =>
        history.push({
          pathname: route
        })
      }
      {...props}>
      {title}
    </Button>
  );
};

GoTo.propTypes = {
  route: string.isRequired,
  title: node.isRequired
};

export default GoTo;
