const getProteinDescriptor = lines => {
  const headers = lines?.shift();
  const idxs = new Map();

  return (line, field, fallback) => {
    if (!headers || !line || !field) {
      return fallback ?? null;
    }

    if (!idxs.has(field)) {
      idxs.set(field, headers.indexOf(field));
    }

    const idx = idxs.get(field);
    if (idx === -1) {
      return fallback ?? null;
    }

    return line[idx];
  };
};

export default getProteinDescriptor;
