import { useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import 'blob-polyfill';

const initialState = {
  loading: {
    fetching: false,
    submitting: false,
  },
};

const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

const loading = {
  setFetching: fetching => setGlobalState('loading', {
    ...getGlobalState('loading'),
    fetching,
  }),

  setSubmitting: submitting => setGlobalState('loading', {
    ...getGlobalState('loading'),
    submitting,
  }),
};

const useLoading = () => {
  const [ state ] = useGlobalState('loading');

  Object.assign(loading, state);

  return useMemo(() => ({
    ...state,
    ...loading,
  }), [ state ]);
};

export default useLoading;
