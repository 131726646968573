import React, { useContext } from 'react';
import { bool, node, string } from 'prop-types';

import metadataContext from '../contexts/metadata';

import Viewport from '../Components/Viewport';
import GoTo from '../Components/GoTo';

const Home = ({
  children, logo, noLogin, onLoginGoTo, ...props
}) => {
  const { siteTitle } = useContext(metadataContext);

  return (
    <Viewport className="Home__wrapper" { ...props }>
      <div className="Home__body">
        <div className="Home__body-logo">
          <img src={ logo } alt={ siteTitle } />
        </div>
        {children}
      </div>

      {!noLogin && (
        <div>
          <GoTo route="/login" title="Sign In (Optional)" variant="outlined" />
          <span
            style={ {
              marginLeft: '4px',
            } }
          >
            <GoTo route="/newjob" title="New Job" variant="contained" />
          </span>
        </div>
      )}
    </Viewport>
  );
};

Home.propTypes = {
  children: node,
  logo: string,
  noLogin: bool,
  onLoginGoTo: string,
};

Home.defaultProps = {
  children: null,
  logo: '',
  noLogin: false,
  onLoginGoTo: '/dashboard',
};

export default Home;
