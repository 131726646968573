import React from 'react';
import Markdown from 'react-markdown';

import Viewport from 'frontend-common/src/Components/Viewport';
import GoTo from 'frontend-common/src/Components/GoTo';

import tos from '../content/screens/tos.json';

const ToS = () => (
  <Viewport
    dashboardBtn={ false }
    newJobBtn={ false }
    updateJobsBtn={ false }
    title={ tos.title }
    topBarButtons={ [
      <GoTo
        key="go-to-proteins-create"
        variant="contained"
        color="secondary"
        route="database"
        title="Database"
      />,
    ] }
  >
    <Markdown>{ tos.body }</Markdown>
  </Viewport>
);

export default ToS;
