import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import classnames from 'classnames';

const Collapsible = ({
  children, className, label, show,
}) => {
  const [ shown, setShown ] = useState(show);

  return (
    <div
      className={ classnames('collapsible', className, {
        'collapsible--shown': shown,
      }) }
    >
      <button
        className="collapsible__toggle"
        onClick={ () => setShown(!shown) }
        type="button"
      >
        { label }
        <img
          className="collapsible__toggle-icon"
          src="../static/media/menu-right.svg"
        />
      </button>
      { shown && (
        <div className="collapsible__content">
          { children }
        </div>
      ) }
    </div>
  );
};

Collapsible.propTypes = {
  children: node,
  className: string,
  label: string,
  show: bool,
};

Collapsible.defaultProps = {
  children: null,
  className: '',
  label: '',
  show: false,
};

export default Collapsible;
