import React from 'react';
import Markdown from 'react-markdown';

import Home from 'frontend-common/src/Screens/Home';
import GoTo from 'frontend-common/src/Components/GoTo';

import ButtonSubmit from '../Components/Button/Submit';

import landingPage from '../content/screens/landing_page.json';

const ScreenHome = () => (
  <Home
    dashboardBtn={ false }
    newJobBtn={ false }
    updateJobsBtn={ false }
    logo="../static/media/stamp-logo.svg"
    noLogin
    title={ landingPage.title }
    topBarButtons={ [
      <GoTo
        key="go-to-proteins-create"
        variant="contained"
        color="secondary"
        route="database"
        title="Database"
      />,
      <ButtonSubmit key="submit" />,
    ] }
  >
    <Markdown>{landingPage.body}</Markdown>
  </Home>
);

export default ScreenHome;
