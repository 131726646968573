import React, { useContext } from 'react';

import { Link } from '@material-ui/core';

import metadataContext from '../contexts/metadata';

import GoTo from './GoTo';

const Footer = () => {
  const { contacts, links, footerMessage } = useContext(metadataContext);

  return (
    <footer className="Footer">

      <div className="Footer__wrapper">

        <div className="Footer__message">
          { footerMessage }
        </div>
        <div className="Footer_links">
          { (links || []).map(link => (
            <GoTo
              key={ `link-${link.route}` }
              route={ link.route }
              title={ link.title }
              variant="text"
              color="primary"
              className="footer__link"
            />
          )) }
        </div>

        <div className="Footer__contacts">
          <div className="Footer__contacts__header">Contacts</div>
          { (contacts || []).map(contact => (
            <Link
              key={ `contact-${contact.email}` }
              className="Footer__contacts__item"
              href={ `mailto:${contact.email}` }
            >
              { contact.email }
            </Link>
          )) }
        </div>
      </div>
    </footer>
  );
};

export default Footer;
