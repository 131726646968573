import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import {
  Authenticator as AWSAuthenticator,
  ConfirmSignUp as AWSConfirmSignUp,
} from 'aws-amplify-react';

import ConfirmSignUp from '../Components/Authenticator/AwaitingConfirmation';
import useAuth from '../Hooks/useAuth';
import Viewport from '../Components/Viewport';

const Login = ({ onLoginGoTo, ...props }) => {
  const { AuthSession } = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    if (AuthSession) {
      push(onLoginGoTo);
    }
  }, [ AuthSession, onLoginGoTo, push ]);

  return !AuthSession && (
    <Viewport
      dashboardBtn={ false }
      newJobBtn={ false }
      updateJobsBtn={ false }
      title="Sign In"
      { ...props }
    >
      <AWSAuthenticator
        hide={ [ AWSConfirmSignUp ] }
        signUpConfig={ {
          hiddenDefaults: [ 'phone_number' ],
        } }
        usernameAttributes="email"
      >
        <ConfirmSignUp />
      </AWSAuthenticator>
    </Viewport>
  );
};

Login.propTypes = {
  onLoginGoTo: string.isRequired,
};

export default Login;
