import React from 'react';

const ComparisonTableLegend = () => (
  <div className="comparison-legend">
    <span>(No correlation) 0</span>
    <div className="comparison-legend-block" />
    <span>1 (Max correlation)</span>
  </div>
);

export default ComparisonTableLegend;
