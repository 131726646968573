import React from 'react';

const IconDownload = () => (
  <svg
    className="inline-icon"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 18.9998H20V11.9998H22V19.9998C22 20.2651 21.8946 20.5194 21.7071 20.707C21.5196 20.8945 21.2652 20.9998 21 20.9998H3C2.73478 20.9998 2.48043 20.8945 2.29289 20.707C2.10536 20.5194 2 20.2651 2 19.9998V11.9998H4V18.9998Z" fill="currentColor" />
    <path d="M12.0002 16.0296L6.34331 10.3727L7.75752 8.95852L11.001 12.202L10.9764 2.2051H12.9761L13.0007 12.2006L16.2428 8.95852L17.657 10.3727L12.0002 16.0296Z" fill="currentColor" />
  </svg>
);

export default IconDownload;
