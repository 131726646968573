import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

import Viewport from 'frontend-common/src/Components/Viewport';

import useLoading from 'frontend-common/src/Hooks/useLoading';
import useProteins from 'frontend-common/src/Hooks/useProteins';

import withAuthenticator from 'frontend-common/src/utils/withAuthenticator';

import getProteinDescriptor from '../utils/getProteinDescriptor';

const NewProtein = () => {
  const { setFetching, setSubmitting } = useLoading();
  const { create, get, listFiles } = useProteins();

  const [ correlation, setCorrelation ] = useState(null);

  const [ suplementaryFile, setSuplementaryFile ] = useState(null);
  const [ suplementary, setSuplementary ] = useState(null);

  useEffect(() => {
    if (!suplementaryFile) {
      setSuplementary(null);
      return;
    }

    (async () => {
      const text = await suplementaryFile.text();
      const lines = text
        .split('\n')
        .filter(line => line)
        .map(line => line.split('\t'));

      setSuplementary(lines);
    })();
  }, [ suplementaryFile ]);

  const [ file, setFile ] = useState(null);
  const [ data, setData ] = useState(null);

  useEffect(() => {
    if (!file) {
      setData(null);
      return;
    }

    setFetching(true);

    (async () => {
      const text = await file.text();
      const lines = text
        .split('\n')
        .filter(line => line)
        .map(line => line.split('\t'));
      const descriptor = getProteinDescriptor(lines);
      const supData = getProteinDescriptor(suplementary);

      // Assume protein is always the same
      const protein = {
        Name: descriptor(lines[0], 'Protein'),
        Sequence: [],
      };

      if (!protein.Name) {
        setData(null);
        setFetching(false);
        return;
      }

      const filesList = await listFiles({
        bucket: 'proteins',
        contentType: 'text/plain',
        level: 'public',
      });

      // See if protein already exists
      const existing = await get(protein.Name);
      if (existing) {
        protein.CreatedAt = existing.protein.CreatedAt;
      }

      const structures = new Map();
      await Promise.all(lines.map(async line => {
        const APR = descriptor(line, 'Experimental.APR') === 'A';
        const dGScaledRaw = descriptor(line, 'dG_scaled');
        const lineDGScaled = Number(dGScaledRaw);
        const dGScaled = dGScaledRaw && !Number.isNaN(lineDGScaled) ? lineDGScaled : null;
        const lineName = descriptor(line, 'Name');
        const name = (lineName.indexOf('-') !== -1 ? lineName.split('-')[1] : lineName).toUpperCase();
        const lineMutant = descriptor(line, 'Mutant');
        const mutant = lineMutant !== 'WT' ? lineMutant : '';
        const res = descriptor(line, 'Res1');
        const resNum = Number(descriptor(line, 'ResNum'));
        const secStr = descriptor(line, 'SecStr');
        const slidingResNum = Number(descriptor(line, 'Sliding_ResNum'));

        const sup = suplementary?.find(sline => supData(sline, 'PDB ID') === name);

        // eslint-disable-next-line prefer-template
        const existingStructure = existing?.structures?.find(structure => structure.Name === name);

        if (!structures.has(name)) {
          const supPatient = supData(sup, 'Patient Derived', existingStructure?.Patient ?? false);

          structures.set(name, {
            Authors: supData(sup, 'Structure Author', existingStructure?.Authors || ''),
            DepDate: supData(sup, 'Dep. Date', existingStructure?.DepDate || ''),
            Description: supData(sup, 'Structure Title', existingStructure?.Description || ''),
            Disease: supData(sup, 'Disease', existingStructure?.Disease || ''),
            Method: supData(sup, 'Exp. Method', existingStructure?.Method || ''),
            Mutant: mutant,
            Name: name,
            Patient: !!supPatient && supPatient !== '-',
            resEnd: resNum,
            resStart: resNum,
            Resolution: supData(sup, 'Resolution or Conformers curated', existingStructure?.Resolution || ''),
            Sequence: [],
            Tissue: supData(sup, 'Tissue', existingStructure?.Tissue || ''),
            UniprotId: supData(sup, 'Uniprot Id', existingStructure?.UniprotId || ''),
            WTPos: supData(sup, 'WT Pos', existingStructure?.WTPos || ''),
          });

          const supProtein = supData(sup, 'Protein');
          if (supProtein && protein.Name !== supProtein) {
            protein.Name = supProtein;
            if (!existing) {
              const existingFromSup = await get(supProtein);
              if (existingFromSup) {
                protein.CreatedAt = existingFromSup.protein.CreatedAt;
              }
            }
          }
        }

        const structure = structures.get(name);
        structure.resEnd = resNum;
        structure.Range = structure.resStart !== resNum ? `${structure.resStart}-${resNum}` : resNum;
        structure.Sequence.push({
          dGScaled,
          res,
          resNum,
          secStr,
          slidingResNum,
        });
        // eslint-disable-next-line prefer-template
        structure.UploadedPdb = filesList?.find(inList => inList.key === protein.Name + '-' + structure.Name + '.pdb');

        if (existingStructure) {
          structure.CreatedAt = existingStructure.CreatedAt;
        }

        // Add this segment to build the protein segment
        const [ , wtRes ] = supData(sup, 'WT Pos')
          ?.split(',')
          .map(item => item.split(' '))
          .find(([ itemPos ]) => Number(itemPos) === resNum) || [];
        const resStr = mutant
          ? (wtRes || res)
          : res;
        protein.Sequence[resNum] = {
          APR,
          residue: resStr,
        };
      }));

      const newData = {
        protein,
        structures: [ ...structures.values() ],
      };

      setData(newData);
      setFetching(false);
    })();
  }, [ file, get, listFiles, setFetching, suplementary ]);

  const handleSubmit = e => {
    e.preventDefault();

    setSubmitting(true);

    (async () => {
      await create(data, correlation);
      setSubmitting(false);
    })();
  };

  const onChangeField = (idx, field, prop) => e => {
    const structure = data.structures[idx];
    const value = e.target[prop || 'value'];

    if (structure[field] !== value) {
      const newStructures = [ ...data.structures ];
      newStructures.splice(idx, 1, {
        ...structure,
        [field]: value,
      });

      setData({
        ...data,
        structures: newStructures,
      });
    }
  };

  const onUploadPDB = idx => files => {
    const structure = data.structures[idx];

    if (structure.pdb !== files[0]) {
      const newStructures = [ ...data.structures ];
      newStructures.splice(idx, 1, {
        ...structure,
        pdb: files[0],
      });

      setData({
        ...data,
        structures: newStructures,
      });
    }
  };

  return (
    <Viewport
      dashboardBtn={ false }
      newJobBtn={ false }
      updateJobsBtn={ false }
      logoutBtn
      title="StAmP Backoffice"
    >
      <Paper className="manage-structures list__wrapper">
        <form
          autoComplete="off"
          noValidate
          onSubmit={ handleSubmit }
        >
          <div className="manage-structures__dnd-wrapper">
            <div>
              <p>Main protein descriptor (tsv)</p>
              <DropzoneArea
                onChange={ files => files[0] && setFile(files[0]) }
                filesLimit={ 1 }
              />
            </div>
            <div>
              <p>Suplementary data file (tsv)</p>
              <DropzoneArea
                onChange={ files => files[0] && setSuplementaryFile(files[0]) }
                filesLimit={ 1 }
              />
            </div>
            <div>
              <p>Correlation matrix (tsv)</p>
              <DropzoneArea
                onChange={ files => setCorrelation(files[0]) }
                filesLimit={ 1 }
              />
            </div>
          </div>

          { data && (
            <>
              <h2>{ data.protein.Name }</h2>

              <div className="list__container">
                <Table className="list">
                  <TableHead>
                    <TableRow className="manage-structure">
                      <TableCell className="list-header list-header__cell">Name</TableCell>
                      <TableCell className="list-header list-header__cell">Uniprot Id</TableCell>
                      <TableCell className="list-header list-header__cell">Residues</TableCell>
                      <TableCell className="list-header list-header__cell">Mutant</TableCell>
                      <TableCell className="list-header list-header__cell x-larger">Description</TableCell>
                      <TableCell className="list-header list-header__cell">Dep. Date</TableCell>
                      <TableCell className="list-header list-header__cell">Method</TableCell>
                      <TableCell className="list-header list-header__cell larger">Resolution</TableCell>
                      <TableCell className="list-header list-header__cell x-larger">Authors</TableCell>
                      <TableCell className="list-header list-header__cell larger">PDB Model</TableCell>
                      <TableCell className="list-header list-header__cell">Patient</TableCell>
                      <TableCell className="list-header list-header__cell larger">Disease</TableCell>
                      <TableCell className="list-header list-header__cell">Tissue</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    { data.structures.map((structure, idx) => (
                      <TableRow key={ structure.Name } hover>
                        <TableCell>{ structure.Name }</TableCell>

                        <TableCell className="small">
                          <TextField
                            onChange={ onChangeField(idx, 'Uniprot Id') }
                            value={ structure.UniprotId }
                          />
                        </TableCell>

                        <TableCell className="small">
                          <TextField
                            multiline
                            onChange={ onChangeField(idx, 'Range') }
                            value={ structure.Range }
                          />
                        </TableCell>

                        <TableCell className="small">
                          <TextField
                            multiline
                            onChange={ onChangeField(idx, 'Mutant') }
                            value={ structure.Mutant }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            multiline
                            onChange={ onChangeField(idx, 'Description') }
                            value={ structure.Description }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            onChange={ onChangeField(idx, 'DepDate') }
                            value={ structure.DepDate }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            onChange={ onChangeField(idx, 'Method') }
                            value={ structure.Method }
                          />
                        </TableCell>

                        <TableCell className="small">
                          <TextField
                            onChange={ onChangeField(idx, 'Resolution') }
                            size="small"
                            value={ structure.Resolution }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            onChange={ onChangeField(idx, 'Authors') }
                            value={ structure.Authors }
                          />
                        </TableCell>

                        <TableCell className="manage-structure__pdb">
                          <DropzoneArea
                            filesLimit={ 1 }
                            onChange={ onUploadPDB(idx) }
                            type="file"
                          />
                          { structure.pdb?.name
                            ? structure.pdb.name
                            : structure.UploadedPdb?.lastModified?.toLocaleString('en-GB', { dateStyle: 'medium', timeStyle: 'medium' }) }
                        </TableCell>

                        <TableCell className="small">
                          <Checkbox
                            checked={ structure.Patient }
                            onChange={ onChangeField(idx, 'Patient', 'checked') }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            onChange={ onChangeField(idx, 'Disease') }
                            value={ structure.Disease }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            onChange={ onChangeField(idx, 'Tissue') }
                            value={ structure.Tissue }
                          />
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </div>
            </>
          ) }

          <Button
            className="ProteinCreate__form__submit"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </form>
      </Paper>
    </Viewport>
  );
};

export default withAuthenticator(NewProtein);
