/**
 * File passive-events.js
 *
 * Detects whether the browser supports passive events and handles accordingly.
 */

import { debounce, throttle } from 'throttle-debounce';

let passiveSupported = false;

try {
  const options = {
    // This function will be called when the browser attempts to access the
    // passive property.
    get passive() {
      passiveSupported = true;
      return false;
    },
  };

  window.addEventListener('test', null, options);
  window.removeEventListener('test', null, options);
}
catch (err) {
  passiveSupported = false;
}

export default (el, type, callback, delay, debounceMode) => {
  const mode = debounceMode ? debounce : throttle;
  const handler = mode(delay || 0, callback);

  el.addEventListener(type, handler, passiveSupported ? { passive: true } : false);

  return () => {
    el.removeEventListener(type, handler, passiveSupported ? { passive: true } : false);
  };
};
