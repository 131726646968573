import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  errors: [],
};

const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

export const errors = {
  add: error => setGlobalState('errors', [].concat(getGlobalState('errors'), [ error ])),

  clear: () => setGlobalState('errors', []),
};

export default () => {
  const [ messages ] = useGlobalState('errors');

  errors.messages = messages;

  return errors;
};
