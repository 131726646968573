import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

import useAuth from '../Hooks/useAuth';

const Logout = () => {
  const { AuthSession, logout } = useAuth();
  const history = useHistory();

  if (!AuthSession) {
    return null;
  }

  const onLogout = () => logout()
    .then(() => {
      history.push('/');
    });

  return (
    <Button
      type="button"
      variant="contained"
      onClick={ onLogout }
      className="Button__logout"
    >
      Logout
    </Button>
  );
};

export default memo(Logout);
