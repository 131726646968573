import React, { useMemo } from 'react';
import {
  arrayOf, bool, number, shape, string,
} from 'prop-types';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import useQueryParams from 'frontend-common/src/Hooks/useQueryParams';

import DGScaleResidueTick from './DGScaleResidueTick';

const DGScale = ({ data }) => {
  const { queryParams } = useQueryParams();
  const strct1 = queryParams.strct1 && data?.structures.find(({ Name }) => Name === queryParams.strct1);
  const strct2 = queryParams.strct2 && data?.structures.find(({ Name }) => Name === queryParams.strct2);

  const values = useMemo(() => data?.protein.Sequence
    .map((entry, resNum) => ({
      ...entry,
      resNum: resNum % 5 === 0 ? resNum : null,
      strct1: strct1?.Sequence.find(seq => seq.resNum === resNum)?.dGScaled,
      strct2: strct2?.Sequence.find(seq => seq.resNum === resNum)?.dGScaled,
      // eslint-disable-next-line prefer-template
      tick: entry?.APR ? entry.residue + '-A' : entry?.residue,
      threshold: 0,
    }))
    .filter(entry => entry?.residue), [ data, strct1, strct2 ]);

  if (!values?.length || (!strct1 && !strct2)) {
    return null;
  }

  return (
    <div className="dgscale__wrapper">
      <div className="dgscale__legend">
        <div className="dgscale__legend-item">
          <div className="dgscale__residue protein-structure--apr" />
          Experimental APR
        </div>

        { !!strct1 && (
          <div className="dgscale__legend-item">
            <div className="dgscale__residue protein-structure--strct1" />
            { strct1.Name }
          </div>
        ) }

        { !!strct2 && (
          <div className="dgscale__legend-item">
            <div className="dgscale__residue protein-structure--strct2" />
            { strct2.Name }
          </div>
        ) }
      </div>
      <div className="protein-dgscale">
        <ResponsiveContainer
          height={ 300 }
          width="100%"
          minWidth={ `${80 + (values.length * 14)}px` }
        >
          <ComposedChart
            data={ values }
            margin={ {
              top: 5,
              right: 20,
              bottom: 5,
              left: 5,
            } }
          >
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis
              dataKey="resNum"
              interval={ 0 }
              xAxisId="resNum"
            />
            <XAxis
              dataKey="tick"
              interval={ 0 }
              orientation="top"
              tick={ <DGScaleResidueTick /> }
              xAxisId="residue"
            />
            <YAxis>
              <Label
                angle={ -90 }
                dx={ -12 }
                value="Stability (scaled dG)"
              />
            </YAxis>

            <Line
              animationDuration={ 500 }
              dataKey="strct1"
              dot={ false }
              stroke="#0138FF"
              strokeDasharray="3 3"
              type="monotone"
              xAxisId="resNum"
            />

            <Line
              animationDuration={ 500 }
              dataKey="strct2"
              dot={ false }
              stroke="#AF00EC"
              strokeDasharray="3 3"
              type="monotone"
              xAxisId="resNum"
            />

            <Line
              animationDuration={ 500 }
              dataKey="threshold"
              dot={ false }
              stroke="#000000"
              type="monotone"
              xAxisId="resNum"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

DGScale.propTypes = {
  data: shape({
    protein: shape({
      Sequence: arrayOf(shape({
        APR: bool,
        residue: string,
      })),
    }),
    structures: arrayOf(shape({
      Name: string,
      Sequence: arrayOf(shape({
        dGScaled: number,
        resNum: number,
      })),
    })),
  }),
};

DGScale.defaultProps = {
  data: null,
};

export default DGScale;
