import React, { useState } from 'react';
import { func } from 'prop-types';

import IconDownload from 'frontend-common/src/Components/Icon/Download';

const ExportIndividual = ({ onClick, ...props }) => {
  const [ exporting, setExporting ] = useState(false);

  return (
    <button
      className="download-button"
      disabled={ exporting }
      onClick={ async () => {
        setExporting(true);
        try {
          await onClick();
        }
        catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
        setExporting(false);
      } }
      type="button"
      { ...props }
    >
      <IconDownload />
      { exporting ? 'Exporting...' : 'Export' }
    </button>
  );
};

ExportIndividual.propTypes = {
  onClick: func.isRequired,
};

export default ExportIndividual;
