import React from 'react';
import GoTo from 'frontend-common/src/Components/GoTo';

const ButtonSubmit = () => (
  <GoTo
    route="submit"
    title="Submit new entry"
    variant="contained"
    color="primary"
  />
);

export default ButtonSubmit;
