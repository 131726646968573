import Amplify, { API, Storage } from 'aws-amplify';
import 'whatwg-fetch';

import '@aws-amplify/ui/dist/style.css';

import { errors } from '../Hooks/useErrors';

import config from './aws-config';

Amplify.configure(config);

// Amplify.configure() only works with one bucket. For us to choose between
// several buckets, we need to initialize each specific bucket each time.
const withBucket = (name) => {
  Storage.configure({
    AWSS3: {
      ...config.Storage,
      bucket: config.Storage.buckets[name]
    }
  });

  return true;
};

const errorHandler = (error) => {
  if (error?.response?.status === 404) {
    return;
  }

  // This is an expected error, such as a forbidden action.
  if (error?.response?.data?.message) {
    errors.add(error.response.data.message);
  }
  // This is an unexpected error, logs should be in CloudWatch.
  else {
    console.error({ error });
    errors.add(
      'The request has failed, please reach out to us if the issue persists.'
    );
  }
};

export default {
  // API

  post: (path, data, service) =>
    API.post(service || 'queue-service', `/${path}`, {
      body: {
        Tool: config.Tool,
        ...data
      }
    }).catch(errorHandler),

  patch: (path, data, service) =>
    API.patch(service, `/${path}`, {
      body: {
        Tool: config.Tool,
        ...data
      }
    }).catch(errorHandler),

  get: (path, service) => API.get(service, `/${path}`).catch(errorHandler),

  delete: (path, service) => API.del(service, `/${path}`).catch(errorHandler),

  // S3

  withBucket,

  // Others

  getNotifyUrl: (jobId) => config.NotifyUrl.replace('%jobId', jobId)
};

export { Auth, Hub, Storage } from 'aws-amplify';
