import React, { useRef, useState } from 'react';
import JSZip from 'jszip';

import { Button, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

import GoTo from 'frontend-common/src/Components/GoTo';
import Viewport from 'frontend-common/src/Components/Viewport';

import useErrors from 'frontend-common/src/Hooks/useErrors';
import useLoading from 'frontend-common/src/Hooks/useLoading';

const Submit = () => {
  const errors = useErrors();
  const { setSubmitting } = useLoading();

  const [ succeeded, setSucceeded ] = useState(false);

  const formRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const proteinRef = useRef();
  const pdbidRef = useRef();
  const [ upload, setUpload ] = useState([]);

  const inputs = [
    [ nameRef ],
    [ emailRef ],
    [ proteinRef ],
    [ pdbidRef ],
  ];

  const onSubmit = e => {
    e.preventDefault();

    if (!inputs.every(([ ref ]) => {
      const valid = ref.current.checkValidity();
      return valid;
    })) {
      return;
    }

    if (!upload?.length) {
      return;
    }

    setSubmitting(true);
    setSucceeded(false);

    (async () => {
      const {
        fetch, location, File, FormData,
      } = window;
      const data = new FormData();
      data.append('form-name', 'submit');
      data.append('name', nameRef.current.value);
      data.append('email', emailRef.current.value);
      data.append('protein', proteinRef.current.value);
      data.append('pdbid', pdbidRef.current.value);

      const zip = new JSZip();
      upload.forEach(file => zip.file(file.name, file));

      const blob = await zip.generateAsync({ type: 'blob' });
      const zippedFiles = new File([ blob ], 'upload.zip', {
        lastModified: Date.now(),
        type: 'application/zip',
      });
      data.append('upload', zippedFiles);

      // We're POSTing directly to the page we're in, Netlify will know what to do with it
      fetch(location.href, {
        method: 'POST',
        body: data,
      })
        .then(response => {
          setSubmitting(false);

          if (!response.ok) {
            errors.add('Something went wrong, please try again or reach out to us via email if the problem persists.');
          }
          else {
            setSucceeded(true);
          }
        })
        .catch(ex => {
          // eslint-disable-next-line no-console
          console.error(ex);

          errors.add('Something went wrong, please try again or reach out to us via email if the problem persists.');
          setSubmitting(false);
        });
    })();
  };

  return (
    <Viewport
      dashboardBtn={ false }
      newJobBtn={ false }
      updateJobsBtn={ false }
      title="Submit"
      topBarButtons={ [
        <GoTo
          key="go-to-proteins-create"
          variant="contained"
          color="secondary"
          route="database"
          title="Database"
        />,
      ] }
    >
      { succeeded && 'Submission was successful!' }

      { !succeeded && (
        <form
          className="Submit__form"
          ref={ formRef }
          noValidate
          onSubmit={ onSubmit }
        >
          <div className="Submit__group">
            <TextField
              className="Submit__name"
              inputRef={ nameRef }
              label="Your name..."
              margin="normal"
              name="name"
              required
              type="text"
              variant="outlined"
            />
          </div>

          <div className="Submit__group">
            <TextField
              className="Submit__email"
              inputRef={ emailRef }
              label="Your email... (optional, so we can reach out if we need more info)"
              margin="normal"
              name="email"
              type="text"
              variant="outlined"
            />
          </div>

          <div className="Submit__group">
            <TextField
              className="Submit__protein"
              inputRef={ proteinRef }
              label="Protein name..."
              margin="normal"
              name="protein"
              required
              type="text"
              variant="outlined"
            />
          </div>

          <div className="Submit__group">
            <TextField
              className="Submit__pdbid"
              inputRef={ pdbidRef }
              label="PDB ID..."
              margin="normal"
              name="pdbid"
              required
              type="text"
              variant="outlined"
            />
          </div>

          <div className="Submit__group">
            <p>
              Please gather all the related pdb structure files into a zip archive and upload it with your request.
            </p>
            <DropzoneArea
              filesLimit={ 1 }
              onChange={ setUpload }
            />
          </div>

          <div>
            <Button
              className="NewJob__submit"
              color="primary"
              onClick={ onSubmit }
              variant="contained"
            >
              Submit new protein
            </Button>
          </div>
        </form>
      ) }
    </Viewport>
  );
};

export default Submit;
