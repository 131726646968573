import { useLocalStorage } from 'react-use';

import config from '../lib/aws-config';

export const LOCAL_AUTH_KEY = `${config.Tool}_session_id`;

const randomUUID = crypto.randomUUID();

export const getLocalSession = () => window.localStorage.getItem(LOCAL_AUTH_KEY);

export default function useLocalSession() {
  const [ localSession ] = useLocalStorage(
    LOCAL_AUTH_KEY,
    randomUUID,
    { raw: true },
  );

  return localSession;
}
